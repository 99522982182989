<template>
  <div class="order_home">
    <div class="bg_img">
      <img src="@/assets/images/img_logo.png" alt="" />
    </div>
    <div class="white_box">
      <van-button
        class="mt_a_60"
        color="#577BB5"
        round
        size="large"
        @click="getCoupon('get')"
        >领取优惠券</van-button
      >
      <van-button
        color="#577BB5"
        round
        size="large"
        @click="getCoupon('search')"
        >查询优惠券</van-button
      >
    </div>
    <van-dialog
      v-model="formShow"
      :title="type == 'get' ? '领取优惠券' : '查询优惠券'"
      show-cancel-button
      :before-close="handleBeforeClose"
    >
      <div class="form_style">
        <van-cell-group>
          <van-field
            v-if="type == 'get'"
            v-model="formData.name"
            label="姓名"
            placeholder="请输入姓名"
          />
          <van-field
            v-model="formData.phone"
            type="tel"
            label="电话"
            placeholder="请输入电话"
          />
          <van-field
            v-if="type == 'get'"
            v-model="formData.coupon_code"
            label="激活码"
            placeholder="请输入激活码"
          />
        </van-cell-group>
      </div>
    </van-dialog>
    <van-dialog
      v-model="couponShow"
      title="优惠券"
      :show-confirm-button="false"
      :before-close="couponHandleBeforeClose"
      :close-on-click-overlay="true"
    >
      <div class="coupon_info_style">
        <div class="left">
          <span class="coupon_name">{{ couponInfo.coupon_title }}</span>
          <span
            >有效期: {{ couponInfo.beg_time }} - {{ couponInfo.end_time }}</span
          >
        </div>
        <div class="right">
          <span>{{
            couponInfo.coupon_state == "1"
              ? "已激活"
              : couponInfo.coupon_state == "2"
              ? "已使用"
              : "已过期"
          }}</span>
        </div>
      </div>
      <div class="bottom_btn" v-if="couponInfo.coupon_state != '3'">
        <van-button
          color="#577BB5"
          style="width: 200px;"
          round
          @click="toUse"
          >{{
            couponInfo.coupon_state == "1" ? "去使用" : "查看订单"
          }}</van-button
        >
      </div>
    </van-dialog>
  </div>
</template>

<script>
import { Toast } from "vant";
import { couponQuery, couponAct, getCouponInfo } from "@/api/couponOrder";
export default {
  name: "orderhome",
  data() {
    return {
      formShow: false,
      type: "",
      formData: {
        name: "",
        phone: "",
        coupon_code: "",
      },
      couponShow: false,
      couponInfo: {},
      couponId: "",
    };
  },
  mounted() {},
  watch: {
    formShow(val) {
      if (!val) {
        this.formData = {
          name: "",
          phone: "",
          coupon_code: "",
        };
      }
    },
  },
  methods: {
    getCoupon(type) {
      this.type = type;
      this.formShow = true;
    },
    handleBeforeClose(action, done) {
      if (action === "confirm") {
        done(false);
        this.confirm();
      } else {
        done();
      }
    },
    confirm() {
      if (this.type == "get") {
        if (!this.formData.name) {
          Toast("请输入姓名");
          return;
        }
        if (!this.formData.phone) {
          Toast("请输入电话");
          return;
        }
        if (!this.formData.coupon_code) {
          Toast("请输入激活码");
          return;
        }
        couponAct(this.formData).then((res) => {
          this.couponId = res.data.coupon_id;
          this.searchCoupon(res.data.coupon_id);
          this.formShow = false;
        });
      }
      if (this.type == "search") {
        if (!this.formData.phone) {
          Toast("请输入电话");
          return;
        }
        couponQuery({ phone: this.formData.phone }).then((res) => {
          this.couponId = res.data.coupon_id;
          this.searchCoupon(res.data.coupon_id);
          this.formShow = false;
        });
      }
    },
    searchCoupon(id) {
      getCouponInfo({ coupon_id: id }).then((res) => {
        this.couponInfo = res.data;
        this.couponShow = true;
      });
    },
    couponHandleBeforeClose(action, done) {
      if (action === "confirm") {
        done(false);
      } else {
        done();
      }
    },
    toUse() {
      if (this.couponInfo.coupon_state == "1") {
        this.$router.push({
          path: "/CouponOrder/useCoupon",
          query: {
            couponId: this.couponId,
            type: this.couponInfo.type,
          },
        });
      }else {
        this.$router.push({
          path: "/CouponOrder/couponOrderInfo",
          query: {
            orderId: this.couponInfo.order_id,
          },
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.order_home {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-image: linear-gradient(to bottom, #f9fbfe, #e8f2ff);
  .bg_img {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    img {
      width: 428px;
      height: 428px;
    }
  }
  .form_style {
    padding: 20px;
  }
  /deep/.van-cell {
    line-height: normal;
  }
  .white_box {
    position: absolute;
    left: 30px;
    right: 30px;
    bottom: 20%;
    width: calc(100vw - 60px);
    height: 439px;
    border-radius: 30px;

    .mt_a_60 {
      margin: 60px auto;
    }

    .mt_a_40 {
      margin: 40px auto;
    }
  }
  .coupon_info_style {
    padding: 40px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 28px;
    .left {
      display: flex;
      flex-direction: column;
    }
  }
  .bottom_btn {
    padding: 20px;
    display: flex;
    justify-content: center;
  }
}
</style>
